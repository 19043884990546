import { useEffect } from 'react'

export const AnalyticsTrigger = ({title}:{title:string}) => {
  const trackingId = "G-9SRV1RGK6Z"
  const uATrackingId = "UA-196102849-1"
  const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

  useEffect(() => {
    if (!isDevelopment){
      window.gtag('js', new Date());
      window.gtag('config', trackingId, {
        'page_title' : title,
        'page_location': window.location.href,
        'page_path': window.location.pathname
      })
      window.gtag('config', uATrackingId, {
        'page_title' : title,
        'page_location': window.location.href,
        'page_path': window.location.pathname
      })
    }
  }, [])

  return (<></>);
}

declare global {
  interface Window {
    gtag: any
  }
}

export default AnalyticsTrigger
