import Link from "next/link"
import Image from "next/image"

const Footer = () => {

  const superReload = () => {
    if('caches' in window){
      caches.keys().then((names) => {
        names.forEach(name => {
          caches.delete(name);
        })
      });
      window.location.reload();
    }
  }

  return (
    <footer>
      <div>
        <div className="content">
          <div>
            <div style={{fontSize: "1.5em", fontWeight: "bold", marginTop: "24px"}}>
              <p onClick={() => superReload()} style={{cursor: "pointer", margin: "0"}}>Debicre</p>
            </div>
            <div style={{fontSize: ".9em"}}>簿記を楽しく効率よく学ぼう！</div>
            <div style={{padding: "16px 0 0", display: "flex", alignItems: "center", gap:"16px"}}>
              <div>
                <a href="https://twitter.com/Debicre_boki" rel="nofollow" target="__blank">
                    <Image alt="twitter logo" src="/static/logos/twitter-white.svg" width={0} height={0} style={{width: "35px", height: "auto"}}/>
                </a>
              </div>
              <div>
                <a href="https://instagram.com/debicre_boki/" rel="nofollow" target="__blank">
                    <Image alt="instagram logo" src="/static/logos/instagram-white.svg" width={0} height={0} style={{width: "25px", height: "auto"}}/>
                </a>
              </div>
              <div>
                <a href="https://www.youtube.com/@debicre_boki" rel="nofollow" target="__blank">
                    <Image alt="youtube logo" src="/static/logos/youtube-white.svg" width={0} height={0} style={{width: "35px", height: "auto"}}/>
                </a>
              </div>
            </div>
          </div>
          <div className="flex_column" style={{alignItems: "start", marginTop: "24px"}}>
            <div className="heading">About</div>
            <div className="flex_column links">
              <Link href="/about" prefetch={false}>
                デビクレについて
              </Link>
              <Link href="/faq" prefetch={false} rel="nofollow">
                よくある質問
              </Link>
              <Link href="/contact" prefetch={false} rel="nofollow">
                お問い合わせ
              </Link>
            </div>
          </div>
          <div className="flex_column" style={{alignItems: "start", marginTop: "24px"}}>
            <div className="heading">Links</div>
            <div className="flex_column links">
              <Link href="/courses" prefetch={false}>
                レッスン一覧
              </Link>
              <Link href="/contests" prefetch={false}>
                模試一覧
              </Link>
              <Link href="/posts" prefetch={false}>
                記事一覧
              </Link>
            </div>
          </div>
          <div className="flex_column" style={{alignItems: "start", marginTop: "24px"}}>
            <div className="heading">Legal</div>
            <div className="flex_column links">
              <Link href="/terms" prefetch={false} rel="nofollow">
                利用規約
              </Link>
              <Link href="/privacy" prefetch={false} rel="nofollow">
                プライバシーポリシー
              </Link>
            </div>
          </div>
        </div>
        <div style={{display: "flex", justifyContent: "center"}}>
          © 2021 Debicre
        </div>
      </div>
    </footer>
  )
};

export default Footer
