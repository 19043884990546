import Link from 'next/link'
import Image from 'next/image'
import { NextSeo } from 'next-seo'
import AnalyticsTrigger from '../components/atoms/AnalyticsTrigger'
import Footer from '../components/organisms/Footer'

const Top = () => {
  return (<>
    <AnalyticsTrigger title={"Topページ"} />
    <NextSeo title="Debicre - デビクレ" />
    <div className="lp">
      <div className="header">
        <div className="logo">Debicre</div>
        <div className="login">
          <Link href="/auth/login" prefetch={false}>
            ログイン
          </Link>
        </div>
      </div>
      <div className="top">
        <div className="contents">
          <div className="slogan">
            <h1>
              <span>簿記を</span>
              <span>楽しく</span>
              <span>効率よく</span>
              <span>学ぼう！</span>
            </h1>
          </div>
        </div>
        <div className="image">
          <Image
            src={"/static/images/calculator.svg"}
            alt="calculator"
            width={450}
            height={450}
            loading="eager"
            priority={true}
          />
        </div>
      </div>
    </div>
    <Footer />
  </>)
}

export default Top
